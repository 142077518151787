<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-12">
        <template>
          <Table
            @btnPrintOnClick="btnPrintOnClick"
            @btnDownloadOnClick="btnDownloadOnClick"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import VueHtml2pdf from "vue-html2pdf"
import Card from '@/view/content/Card.vue'
import Table from '@/component/reports/PaymentPharmacyReport.vue'
import Print from '@/component/reports-print/PaymentPharmacyReport.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Table,
    VueHtml2pdf,
    Print
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: '',
        patient_name: '',
        start_date: '',
        end_date: ''
      },
      total: 0,
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Items
      items: [],
      listData: [],
      summary: 0,
    }
  },

  

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan", route: "" },
      { title: "Laporan Pendapatan" },
    ])
  },

}
</script>

<style>
</style>