<template>
  <div>

    <div class="d-flex justify-content-start align-items-center mt-5">
      <button
        class="btn btn-primary mr-1"
        v-b-tooltip.hover
        title="Print & Pdf"
        @click="btnPrintOnClick"
      >
        <i class="fas fa-print px-0"></i>
      </button>
      
      <button
        class="btn btn-warning mr-1"
        v-b-tooltip.hover
        title="Download Excel"
        @click="btnExcelOnClick"
      >
        <i class="fas fa-file-excel px-0"></i>
      </button>
    </div>

    <div class="d-flex justify-content-end align-items-center mt-6">
      <span
        style="width: 150px; color: #245590 !important"
        class="font-weight-bold"
        >Total Terbayar:</span
      >
      <span class="font-weight-bold" style="color: #245590 !important">{{
        "Rp. " + parseInt(summary).toLocaleString("id-ID")
      }}</span>
    </div>

    <!-- Table -->
    <b-table
      striped
      class="mt-3"
      hover
      :items="items"
      :fields="fields"
      responsive
      :style="'white-space: nowrap'"
    >
      <template #cell(medical_list)="data">
        <div v-for="(service, index) in data.item.services" :key="index">
          <li>
            {{
              `${service.name} : ${parseInt(service.price).toLocaleString(
                "id-ID"
              )}`
            }}
          </li>
        </div>
      </template>
      <template #cell(total_amount)="data">
        {{ parseInt(data.item.total_amount).toLocaleString("id-ID") }}
      </template>
      <template #cell(tax)="data">
        {{ parseInt(data.item.tax).toLocaleString("id-ID") }}
      </template>
      <template #cell(discount)="data">
        {{ parseInt(data.item.discount).toLocaleString("id-ID") }}
      </template>
      <template #cell(final_amount)="data">
        <ul>
          <li>Total :{{ parseInt(data.item.final_amount).toLocaleString("id-ID") }}</li>
          <li>Terbayar :{{ parseInt(data.item.paid_amount).toLocaleString("id-ID") }}</li>  
        </ul>
        
      </template>
      <template #cell(surcharge)="data">
        {{ parseInt(data.item.surcharge).toLocaleString("id-ID") }}
      </template>
    </b-table>

    <!-- PDF -->
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="'Laporan Pendapatan Rumah Sakit'"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="780px"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
        >
          <section slot="pdf-content">
            <Print
              :items="items"
              :summary="summary"
              :perPage="perPage"
              :currentPage="currentPage"
              :totalRows="totalRows"
             
            />
          </section>
        </vue-html2pdf>
  </div>
</template>

<script>
import XLSX from "xlsx";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Print from '@/component/reports-print/PaymentReport.vue'
import VueHtml2pdf from "vue-html2pdf"

export default {
  props: {
    dataModal:Array,
    excel:Array,
    currentPage: Number,
    perPage: Number,
    totalRows: Number
  },

  components: {
    Treeselect,
    XLSX,
    VueHtml2pdf,
    Print,
    // moment,
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: '',
        patient_name: '',
        start_date: '',
        end_date: ''
      },
      // Table Items
      items: [],
      listData: [],
      summary: 0,
      excelData:[],
      // field
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_created_at",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: true,
        },

        {
          key: "medical_list",
          label: "Daftar Layanan",
          sortable: true,
        },
        {
          key: "total_amount",
          label: "Subtotal",
          sortable: true,
        },
        {
          key: "tax",
          label: "Pajak",
          sortable: true,
        },
        {
          key: "discount",
          label: "Diskon",
          sortable: true,
        },
        {
          key: "surcharge",
          label: "Surcharge",
          sortable: true,
        },
        {
          key: "final_amount",
          label: "Total",
          sortable: true,
        },
        {
          key: "notes",
          label: "Catatan",
          sortable: true,
        },
      ],
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
    };
  },

  methods: {
    btnExcelOnClick() {
      console.log('=========================================',this.excelData);
      this.excelData.forEach(obj => delete obj.id);
      console.log('========================================= new',this.excelData);
      const data = XLSX.utils.json_to_sheet(this.excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Laporan Pendapatan Rumah Sakit.xlsx");
    },

    async pagination() {
      this.excelData = this.excel
      this.items = this.dataModal
      this.totalRows = this.dataModal.length

      let a
      this.summary = 0
      for (a = 0; a < this.items.length; a++) {
          let sum = this.items[a].paid_amount - this.items[a].change_amount
          this.summary += parseInt(sum)
      }

      console.log(this.summary,"Summary");
    },

     btnPrintOnClick() {
      this.$htmlToPaper('print')
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf()
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },

  },

  mounted() {
    this.pagination()
  },
};
</script>

<style>
</style>