<template>
  <div
    id="print"
  >
    <div class="d-flex justify-content-center align-items-center mt-3">
      <h5>Laporan Pengeluaran Apotik</h5>
    </div>
    
    <div class="d-flex justify-content-end align-items-center mt-6 mr-6">
      <span style="width: 150px" class="font-weight-bold">Total Pengeluaran :</span>
      <span class="font-weight-bold">{{ "Rp. " + parseInt(summary).toLocaleString('id-ID') }}</span>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      class="mt-3"
      :items="items"
      :fields="fields"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '20%' : '' }"
        />
      </template>
       <template #cell(display_amount)="data">
        {{ parseInt(data.item.display_amount).toLocaleString("id-ID") }}
      </template>
    </b-table>

  </div>
</template>

<script>

export default {

  props: {
    items: Array,
    summary: Array,
    perPage: Number,
    currentPage: Number,
    totalRows: Number
  },

  data() {
    return {
      // Filter
      filter: {
        start_date: '',
        end_date: ''
      },
      // Table Fields
      fields: [
        {
          key: "number",
          label: "#",
          tdClass: "text-sizing",
          thClass: "text-sizing"
        },
        {
          key: "date_display",
          label: "Tanggal",
          tdClass: "text-sizing",
          thClass: "text-sizing"
        },
        {
          key: "name",
          label: "Keterangan",
          tdClass: "text-sizing",
          thClass: "text-sizing"
        },
        {
          key: "pharmacy_expense_category_name",
          label: "Kategory",
          tdClass: "text-sizing",
          thClass: "text-sizing"
        },
        {
          key: "display_amount",
          label: "Nominal (Rp)",
          tdClass: "text-sizing",
          thClass: "text-sizing"
        },
        {
          key: "created_by_name",
          label: "Pembuat",
          tdClass: "text-sizing",
          thClass: "text-sizing"
        },
      ],
    }
  },

  methods: {

  }

}
</script>

<style>
.text-sizing{
  font-size: 10px;
}
</style>