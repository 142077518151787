<template>
  <div>
  
    <div class="d-flex justify-content-start align-items-center mt-5">
      <!-- <button
        class="btn btn-success mr-1"
        v-b-tooltip.hover
        title="Grafik"
        @click="$router.push('/report/payment-chart')"
      >
        <i class="fas fa-chart-bar px-0"></i>
      </button> -->
      <button
        class="btn btn-primary mr-1"
        v-b-tooltip.hover
        title="Print"
        @click="btnPrintOnClick"
      >
        <i class="fas fa-print px-0"></i>
      </button>
      <button
        class="btn btn-info mr-1"
        v-b-tooltip.hover
        title="Download Pdf"
        @click="btnDownloadOnClick"
      >
        <i class="fas fa-file-pdf px-0"></i>
      </button>
      <!-- <button
        class="btn btn-warning mr-1"
        v-b-tooltip.hover
        title="Download Excel"
        @click="btnExcelOnClick"
      >
        <i class="fas fa-file-excel px-0"></i>
      </button> -->
    </div>

    <div class="d-flex justify-content-end align-items-center mt-6">
      <span
        style="width: 150px; color: #245590 !important"
        class="font-weight-bolder"
        >Total :</span
      >
      <span class="font-weight-bolder" 
        style="color:#245590 !important;"
      >{{ "Rp. " + parseInt(summary).toLocaleString('id-ID')}}</span>
      <!-- >{{ summary.length != 0 ? "Rp. " + parseInt(summary[0].total).toLocaleString('id-ID') : '' }}{{this.sum}}</span> -->
    </div>

    <!-- Table -->
    <b-table
      striped
      class="mt-3"
      hover
      :items="items"
      :fields="fields"
      responsive="sm"
      style="white-space: nowrap"
    >
      
       <template #cell(product_list)="data">
        <div
          v-for="(product, index) in data.item.product"
          :key="index"
        >
          <!-- {{data.item.product[index]}} -->
          
          <li>
            {{ `${data.item.product[index]} ( ${data.item.quantity[index]} X ${parseInt(data.item.price[index]).toLocaleString('id-ID')} )` }}
          </li>
        </div>
      </template>

      <template #cell(total_amount)="data">
        Rp
        {{
          parseInt(
            data.item.total_amount
          ).toLocaleString("id-ID")
        }}
      </template>
    </b-table>
  

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'Laporan Pendapatan Obat Toko Online'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <Print
        :items="items"
        :summary="summary"
        v-if="dataLoaded == true"
        />
      </section>
    </vue-html2pdf>

  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf"
import Print from '@/component/reports-print/EcommerceRecordReport.vue'
import XLSX from 'xlsx';
import Treeselect from '@riophae/vue-treeselect'
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'


export default {
  props: {
    // hasPatient: Boolean,
    // items: Array,
    // listData: Array,
    // summary: Number,
    // perPage: Number,
    // currentPage: Number,
    // totalRows: Number
    dataModal:Array,
    excel:Array,
  },

  components: {
    Treeselect,
    XLSX,
    VueHtml2pdf,
    Print
  },

  data() {
    return {
     // Filter
      filter: {
        customer_name: '',
        start_date: '',
        end_date: ''
      },
      total: 0,
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Items
      items: [],
      listData: [],
      summary: 0,
      dataLoaded:false,

      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "transaction_id",
          label: "Kode Transaksi",
          sortable: false,
        },
        {
          key: "display_created_at",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "customer_name",
          label: "Pelanggan",
          sortable: false,
        },
        {
          key: "product_list",
          label: "Daftar Produk",
          sortable: true,
        },
        {
          key: "total_amount",
          label: "Total",
          sortable: true,
        },
      ],
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
    };
  },

  methods: {
    

    // pageOnClick(evt, page) {
    //   evt.preventDefault()
    //   this.currentPage = page
    //   this.pagination()
    // },

    // resetFilter() {
    //   this.filter.start_date = ''
    //   this.filter.end_date = ''
    //   this.filter.customer_name = ''
    //   this.pagination()
    // },

    async pagination() {
     
      this.items = this.dataModal
     
      let a
      this.summary = 0
      for (a = 0; a < this.items.length; a++) {
          this.summary += parseInt(this.items[a].total_amount)
      }
      this.dataLoaded = true
    },

    // generalFilter() {
      
    //   // this.filter.start_date = sended.start_date
    //   // this.filter.end_date = sended.end_date
    //   // this.filter.customer_name = sended.customer_name
    //   this.pagination()
    // },

    // perPageNumber() {
    //   // this.perPage = sended
    //   this.pagination()
    // },

    // filterByPatient() {
    //   // this.resetFilter()
    //   // this.filter.customer_name = sended.customer_name
    //   this.pagination()
    // },

    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.items)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'Laporan Pendapatan Obat Toko Online.xlsx')
    },

    btnPrintOnClick() {
      this.$htmlToPaper('printEcommerce')
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf()
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },


  },

  mounted() {
    // this.summary()
    this.pagination()
  }

}
</script>

<style>
</style>