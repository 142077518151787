<template>
  <div>

    <div class="d-flex justify-content-start align-items-center mt-2">
      <!-- <button
        class="btn btn-success mr-1"
        v-b-tooltip.hover
        title="Grafik"
        @click="$router.push('/report/pharmacy-expense-chart')"
      >
        <i class="fas fa-chart-bar px-0"></i>
      </button> -->
      <button
        class="btn btn-primary mr-1"
        v-b-tooltip.hover
        title="Print"
        @click="btnPrintOnClick"
      >
        <i class="fas fa-print px-0"></i>
      </button>
      <button
        class="btn btn-info mr-1"
        v-b-tooltip.hover
        title="Download Pdf"
        @click="btnDownloadOnClick"
      >
        <i class="fas fa-file-pdf px-0"></i>
      </button>
      <button
        class="btn btn-warning mr-1"
        v-b-tooltip.hover
        title="Download Excel"
        @click="btnExcelOnClick"
      ><i class="fas fa-file-excel px-0"></i></button>
     
    </div>

    <div class="d-flex justify-content-end align-items-center mt-6">
      <span
        style="width: 150px; color: #245590 !important"
        class="font-weight-bold"
        >Total :</span
      >
      <span
        v-if="summary.length != 0"
        class="font-weight-bold"
        style="color: #245590 !important"
        >{{ "Rp. " + parseInt(summary).toLocaleString('id-ID') }}</span
      >
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      class="mt-3"
      :items="items"
      :fields="fields"
      responsive
      :style="'white-space: nowrap'"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '20%' : '' }"
        />
      </template>
      <template #cell(display_amount)="data">
        {{ parseInt(data.item.display_amount).toLocaleString("id-ID") }}
      </template>
    </b-table>

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'Laporan Pengeluaran Apotik'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >

      <section slot="pdf-content">
        <Print
          :items="items"
          :summary="summary"
        />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import XLSX from "xlsx";
import VueHtml2pdf from "vue-html2pdf"
import module from "@/core/modules/CrudModule.js";
import Print from '@/component/reports-print/PharmacyExpenseReport.vue'

export default {
  props: {
    // items: Array,
    // summary: Array,
    // perPage: Number,
    // currentPage: Number,
    // totalRows: Number,
    dataModal:Array,
    excel:Array,
  },

  components: {
    XLSX,
    VueHtml2pdf,
    Print
  },

  data() {
    return {
      // data
      summary: [],
      // Table Items
      items: [],
      excelData:[],
      
      // Table Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date_display",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "name",
          label: "Keterangan",
          sortable: true,
        },
        {
          key: "pharmacy_expense_category_name",
          label: "Kategori",
          sortable: true,
        },
        {
          key: "display_amount",
          label: "Nominal (Rp)",
          sortable: true,
        },
        {
          key: "created_by_name",
          label: "Pembuat",
          sortable: true,
        },
      ],
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
    };
  },

  methods: {

    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Laporan Pengeluaran Apotik.xlsx");
    },

    async pagination() {
     
      this.items = this.dataModal
      this.excelData = this.excel

      let a
      this.summary = 0
      for (a = 0; a < this.items.length; a++) {
          this.summary += parseInt(this.items[a].display_amount)
      }
    },

    

    btnPrintOnClick() {
      this.$htmlToPaper('print')
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf()
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },
  },
  mounted() {
    // Get Data
    this.pagination()
  },
};
</script>

<style>
</style>