<template>
  <div id="printEcommerce">
     <div class="d-flex justify-content-center align-items-center mt-3">
      <h5>Laporan Pendapatan Obat Apotek Online</h5>
    </div>
    <div class="d-flex justify-content-end align-items-center mt-6">
      <span
        style="width: 150px; color: #245590 !important"
        class="font-weight-bolder"
        >Total :</span
      >
      <span class="font-weight-bolder" 
        style="color:#245590 !important;"
      >{{ "Rp. " + parseInt(summary).toLocaleString('id-ID')}}</span>
      <!-- >{{ summary.length != 0 ? "Rp. " + parseInt(summary[0].total).toLocaleString('id-ID') : '' }}{{this.sum}}</span> -->
    </div>

    <!-- Table -->
    <b-table
      striped
      class="mt-3"
      hover
      :items="items"
      :fields="fields"
      responsive="sm"
      style="white-space: nowrap"
    >
      
       <template #cell(product_list)="data">
        <div
          v-for="(product, index) in data.item.product"
          :key="index"
        >
          <!-- {{data.item.product[index]}} -->
          
          <li>
            <span class="text-sizing">

            {{ `${data.item.product[index]} ( ${data.item.quantity[index]} X ${parseInt(data.item.price[index]).toLocaleString('id-ID')} )` }}
            </span>
          </li>
        </div>
      </template>

      <template #cell(total_amount)="data">
        <span class="text-sizing">
          Rp
          {{
            parseInt(
              data.item.total_amount
            ).toLocaleString("id-ID")
          }}
        </span>
      </template>
    </b-table>

  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf"
import Print from '@/component/reports-print/PaymentPharmacyReport.vue'
import XLSX from 'xlsx';
import Treeselect from '@riophae/vue-treeselect'
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'


export default {
  props: {
    // hasPatient: Boolean,
    items: Array,
    listData: Array,
    summary: Number,
  },

  components: {
    Treeselect,
    XLSX,
    VueHtml2pdf,
    Print
  },

  data() {
    return {
     
      total: 0,
    
      // Table Items
      // items: [],
      // listData: [],
      // summary: 0,
      dataLoaded:false,

      fields: [
        {
          key: "number",
          label: "#",
          thClass:"text-sizing",
          tdClass:"text-sizing"
          
        },
        {
          key: "transaction_id",
          label: "Kode Transaksi",
          thClass:"text-sizing",
          tdClass:"text-sizing"
          
        },
        {
          key: "display_created_at",
          label: "Tanggal",
          thClass:"text-sizing",
          tdClass:"text-sizing"
          
        },
        {
          key: "customer_name",
          label: "Pelanggan",
          thClass:"text-sizing",
          tdClass:"text-sizing"
          
        },
        {
          key: "product_list",
          label: "Daftar Produk",
          thClass:"text-sizing",
          tdClass:"text-sizing"
          
        },
        {
          key: "total_amount",
          label: "Total",
          thClass:"text-sizing",
          tdClass:"text-sizing"
          
        },
      ],
     
    };
  },

  // methods: {
  //   async pagination() {
  //     let filterParams = `&customer_name=${this.filter.customer_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
  //     let response = await module.paginate('ecommerce-reports', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      
  //     let pagination = response.meta.pagination
  //     this.items = response.data
  //     this.totalRows = pagination.total

  //     let f, g
  //     g = this.perPage * (this.currentPage - 1) + 1
  //     for (f = 0; f < response.data.length; f++) {
  //       response.data[f].number = g + f
  //     }

  //     let a
  //     this.summary = 0
  //     for (a = 0; a < this.items.length; a++) {
  //         this.summary += parseInt(this.items[a].total_amount)
  //     }
  //     this.dataLoaded = true
  //   },

   
  // },

  // mounted() {
  //   // this.summary()
  //   this.pagination()
  // }

}
</script>

<style>
.text-sizing{
  font-size: 10px;
}
</style>